import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-42b19a73"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "review" }
const _hoisted_2 = ["src"]
const _hoisted_3 = { class: "review-text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_star = _resolveComponent("cn-star")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("img", {
      title: "quote-image",
      class: "quote",
      src: _ctx.quote
    }, null, 8, _hoisted_2),
    _createVNode(_component_cn_star, { rating: _ctx.rating }, null, 8, ["rating"]),
    _createElementVNode("p", _hoisted_3, [
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _createElementVNode("p", null, _toDisplayString(_ctx.name), 1)
  ]))
}