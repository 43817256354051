import { computed } from "vue";

export function useImage(image: string) {
  const imageUrl = computed(() => require("@/assets/images/" + image));
  return imageUrl;
}

export function useIcon(icon: string) {
  const imageUrl = computed(() => require("@/assets/icons/" + icon));
  return imageUrl;
}
