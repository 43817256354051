<template>
  <cn-offer
    title="Glättendes Anti-Aging"
    image="s005.jpg"
    subTitle="Ultraschall Behandlung SkinQ2"
    price="CHF 180.00"
    duration="ca. 90 Min"
  >
    <p>
      Ultraschalltechnologie lässt Ihre Haut erstrahlen und bewirkt einen
      Lifting- und Straffungseffekt. Erleben Sie den Vorher- Nachher Unterschied
      und lassen Sie sich von mir beraten.
    </p>

    <h6><span>Das Resultat ist ein</span></h6>
    <div class="listing">
      <ul>
        <li>perfekter Teint</li>
        <li>Hautbildverfeinerung</li>
        <li>Festigung des Gewebes</li>
        <li>Straffung und Glättung</li>
        <li>Reduktion von feinen Falten</li>
        <li>Einschleusen von Seren</li>
        <li>Sauerstoffanreicherung</li>
      </ul>
    </div>
  </cn-offer>
</template>

<script lang="ts">
import CnOffer from "@/components/CnOffer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnOffer,
  },
});
</script>
