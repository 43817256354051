import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_burger_menu = _resolveComponent("cn-burger-menu")!
  const _component_cn_main_links = _resolveComponent("cn-main-links")!
  const _component_cn_main_menu = _resolveComponent("cn-main-menu")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_cn_main_content = _resolveComponent("cn-main-content")!
  const _component_cn_main_wrapper = _resolveComponent("cn-main-wrapper")!

  return (_openBlock(), _createBlock(_component_cn_main_wrapper, null, {
    default: _withCtx(() => [
      _createVNode(_component_cn_burger_menu),
      _createVNode(_component_cn_main_menu, null, {
        default: _withCtx(() => [
          _createVNode(_component_cn_main_links)
        ]),
        _: 1
      }),
      _createVNode(_component_cn_main_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view, null, {
            default: _withCtx(({ Component }) => [
              _createVNode(_Transition, {
                name: "scale",
                duration: 800
              }, {
                default: _withCtx(() => [
                  (_openBlock(), _createBlock(_resolveDynamicComponent(Component)))
                ]),
                _: 2
              }, 1024)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}