<template>
  <cn-page>
    <cn-page-header
      :image="image"
      :title="title"
      :subtitle="subtitle"
      :imagePosition="imagePosition"
    ></cn-page-header>
    <cn-page-content>
      <cn-grid-container>
        <cn-grid-columns amount="twelve">
          <slot />
        </cn-grid-columns>
      </cn-grid-container>
    </cn-page-content>
    <footer></footer>
  </cn-page>
</template>

<script lang="ts">
import CnPage from "@/components/CnPage.vue";
import CnPageHeader from "@/components/CnPageHeader.vue";
import CnPageContent from "@/components/CnPageContent.vue";
import CnGridContainer from "@/components/CnGridContainer.vue";
import { defineComponent } from "vue";
import CnGridColumns from "@/components/CnGridColumns.vue";

export default defineComponent({
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      required: true,
    },
    imagePosition: {
      type: String,
    },
  },
  components: {
    CnPage,
    CnPageHeader,
    CnGridContainer,
    CnGridColumns,
    CnPageContent,
  },
});
</script>
