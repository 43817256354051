<template>
  <cn-page>
    <cn-page-header
      title="Willkommen in meinem neuen Studio"
      subtitle="Ihr Wunsch ist mein Anliegen"
      image="s031.jpg"
    ></cn-page-header>
    <cn-page-content>
      <cn-grid-container>
        <cn-grid-row class="left">
          <cn-grid-columns amount="six">
            <h5>Gerne berate ich sie persönlich</h5>
            <cn-grid-row :space="true">
              <h6>E-mail</h6>
              <span>nina-g[at]sunrise.ch</span>
            </cn-grid-row>
            <cn-grid-row space>
              <h6>Telefon</h6>
              <span>+41 (0)79 695 60 13</span><br />
              <span>+41 (0)52 364 26 63 (Anrufbeantworter)</span>
            </cn-grid-row>
            <cn-grid-row space>
              <h6>Adresse</h6>
              <address>
                cosmetic-nina<br />
                Hauptstrasse 35 <br />
                CH-8357 Guntershausen <br />
              </address>
            </cn-grid-row>
            <cn-grid-row space>
              <h6>Öffnungszeiten</h6>
              <table border="0">
                <tr>
                  <td>Montag</td>
                  <td>13:30-18:30</td>
                </tr>
                <tr>
                  <td>Dienstag</td>
                  <td>08:00-12:00, 13:30-18:30</td>
                </tr>
                <tr>
                  <td>Mittwoch</td>
                  <td>Geschlossen</td>
                </tr>
                <tr>
                  <td>Donnerstag</td>
                  <td>08:00-12:00, 13:30-18:30</td>
                </tr>
                <tr>
                  <td>Freitag</td>
                  <td>08:00-12:00, 13:30-18:30</td>
                </tr>
                <tr>
                  <td>Samstag</td>
                  <td>Auf Anfrage</td>
                </tr>
                <tr>
                  <td>Sonntag</td>
                  <td>Geschlossen</td>
                </tr>
              </table>
            </cn-grid-row>
          </cn-grid-columns>
          <cn-grid-columns amount="six">
            <h5>Kontakt Formular</h5>
            <div class="box">
              <form id="contact_form" @submit="submit">
                <input
                  class="full-width"
                  type="text"
                  placeholder="Name"
                  v-model="name"
                  :disabled="sending"
                  required="required"
                />
                <input
                  class="full-width"
                  type="email"
                  placeholder="E-Mail Adresse"
                  :disabled="sending"
                  v-model="email"
                  required="required"
                />
                <textarea
                  class="full-width"
                  type="text"
                  :disabled="sending"
                  placeholder="Nachricht"
                  v-model="message"
                  required="required"
                ></textarea>
                <input
                  :disabled="sending"
                  class="button-primary"
                  type="submit"
                  name="co_send"
                  value="Senden"
                />
                <span v-show="sending">Senden...</span>
              </form>
              <div class="contact-reply" :class="{ hidden: !success }">
                <p>
                  {{ returnMessage }}
                </p>
              </div>
            </div>
          </cn-grid-columns>
        </cn-grid-row>
        <cn-grid-row>
          <cn-grid-columns amount="twelve">
            <h5>Anfahrtsplan</h5>
            <p>
              Mehere Parkmöglichkeiten stehen direkt vor dem Studio zur
              Verfügung.
            </p>
            <div class="map-wrapper">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2696.543516833253!2d8.91252101641016!3d47.47932427917627!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479a9461e31d0835%3A0xf976ad8d1f3ac05f!2scosmetic+Nina!5e0!3m2!1sde!2sch!4v1508949737249"
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </cn-grid-columns>
        </cn-grid-row>
      </cn-grid-container>
    </cn-page-content>
  </cn-page>
</template>

<script lang="ts">
import CnPage from "@/components/CnPage.vue";
import CnPageHeader from "@/components/CnPageHeader.vue";
import CnPageContent from "@/components/CnPageContent.vue";
import CnGridContainer from "@/components/CnGridContainer.vue";
import { defineComponent, ref } from "vue";
import CnGridColumns from "@/components/CnGridColumns.vue";
import CnGridRow from "@/components/CnGridRow.vue";

export default defineComponent({
  components: {
    CnPage,
    CnGridRow,
    CnPageHeader,
    CnGridContainer,
    CnGridColumns,
    CnPageContent,
  },
  setup() {
    const returnMessage = ref(
      "Besten Dank für Ihre Nachricht. Ich werde mich schnellstmöglich mit Ihnen in Verbindung setzen.",
    );
    const name = ref("");
    const email = ref("");
    const message = ref("");
    const sending = ref(false);
    const success = ref(false);
    const submit = (e: Event) => {
      e.preventDefault();
      sending.value = true;

      fetch("/api/sendMail", {
        method: "POST",
        body: JSON.stringify({
          Name: name.value,
          Email: email.value,
          Message: message.value,
        }),
      })
        .then((resp) => {
          if (!resp.ok) {
            throw new Error(resp.statusText);
          }
        })
        .then(() => {
          name.value = "";
          email.value = "";
          message.value = "";
        })
        .catch((err) => {
          returnMessage.value =
            "Leider ist etwas schiefgelaufen. Bitte setze dich doch telefonisch mit mir in Verbindung.";
          console.warn(err);
        })
        .finally(() => {
          sending.value = false;
          success.value = true;
          setTimeout(() => (success.value = false), 4000);
        });
    };
    return {
      returnMessage,
      name,
      email,
      message,
      success,
      sending,
      submit,
    };
  },
});
</script>

<style lang="scss" scoped>
.left {
  text-align: left;
}

.full-width {
  width: 100%;
  box-sizing: border-box;
}

h6 {
  font-weight: 900;
  margin-bottom: 0;
}

th,
td {
  padding: 12px 15px;
  text-align: left;
  border-bottom: 1px solid #e1e1e1;

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
}

.box {
  position: relative;
  overflow: hidden;

  #contact_form {
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
  }

  .contact-reply {
    opacity: 1;
    text-align: center;
    transition: all 1.5s ease;
    position: absolute;
    bottom: 0;
    height: 100%;
    width: 100%;
    background: var(--color-white);
    color: #4bb543;

    &.hidden {
      opacity: 0;
      bottom: -100%;
    }

    .glyphicon {
      font-size: 5rem;
    }
  }
}
</style>
