<template>
  <figure>
    <router-link :to="link">
      <img :src="imageUrl" />
      <header>
        <h5>{{ header }}</h5>
        <small>{{ subheader }}</small>
        <div class="more-information">
          <span>Mehr Informationen</span>
          <img :src="icon" />
        </div>
      </header>
    </router-link>
  </figure>
</template>

<script lang="ts">
import { useIcon, useImage } from "@/use/useImage";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    link: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    header: {
      type: String,
      required: true,
    },
    subheader: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const imageUrl = useImage(props.image);
    const icon = useIcon("arrow.svg");
    return {
      icon,
      imageUrl,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";

h5 {
  margin-bottom: 5px;
}

figure {
  @include MG(Tablet) {
    border-style: solid;
    border-color: var(--color-white);
    width: calc(100% / 2);

    &:nth-child(even) {
      border-width: 2px 2px 0px 1px;
    }

    &:nth-child(odd) {
      border-width: 2px 1px 0px 2px;
    }
  }

  width: 100%;
  display: block;
  line-height: 0;
  float: left;
  margin: 0;
  padding: 0;
  position: relative;
  overflow: hidden;

  img {
    width: 100%;
    transition: transform 0.4s;
  }

  header {
    text-align: left;
    position: absolute;
    z-index: 2;
    padding: 10% 5%;
    background: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
    top: 0;
    opacity: 0.5;
    transition: opacity 0.4s ease-out;

    @media (hover: hover) {
      opacity: 0;
    }

    .more-information {
      position: absolute;
      display: flex;
      align-items: center;
      gap: 15px;
      bottom: 30px;
      right: 15px;
      span {
        padding: 3px;
        line-height: 16px;
      }
      img {
        width: 16px;
        height: 16px;
      }
    }
  }
}

a {
  overflow: hidden;
  color: var(--color-white);

  &:hover {
    header {
      opacity: 1;
    }

    img {
      transform: scale(1.1);
    }
  }
}
</style>
