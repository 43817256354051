<template>
  <cn-page>
    <cn-page-header
      image="s004.jpg"
      title="Meine Angebote in der Übersicht"
      subtitle="Wählen Sie ihre Lieblingsbehandlung"
    ></cn-page-header>
    <cn-page-content :fullScreen="true">
      <div class="portfolio">
        <cn-portfolio
          link="/offers/express-treatment"
          image="s017.jpg"
          header="kurz und gründlich"
          subheader="Express Behandlung"
        />
        <cn-portfolio
          link="/offers/Teenager"
          image="s019.jpg"
          header="Für junge Menschen bis zum 20. Lebensjahr"
          subheader="Teenager-Behandlung"
        />
        <cn-portfolio
          link="/offers/classic-massage"
          image="s002.jpg"
          header="Lust auf Beauty und Wellness?"
          subheader="Klassische Behandlung mit Massage"
        />
        <cn-portfolio
          link="/offers/express-ultrasonic"
          image="s006.jpg"
          header="Kurz, gründlich und effektvoll"
          subheader="Express Behandlung mit Ultraschall"
        />
        <cn-portfolio
          link="/offers/Mikrodermabrasion"
          image="s008.jpg"
          header="Der Weg zur perfekten Haut"
          subheader="Mikrodermabrasion"
        />
        <cn-portfolio
          link="/Offers/Anti-Aging"
          image="s005.jpg"
          header="Glättendes Anti-Aging"
          subheader="Ultraschall Behandlung SkinQ2"
        />
        <cn-portfolio
          link="/Offers/Lymphdrainage"
          image="s004.jpg"
          header="Genuss pur"
          subheader="Manuelle Gesichts-Lymphdrainage"
        />
        <cn-portfolio
          link="/Offers/Additionals"
          image="s011.jpg"
          header="Mehr für Ihr Wohlbefinden"
          subheader="Zusatzangebote"
        />
        <cn-portfolio
          link="/Offers/Cosmetic"
          image="s013.jpg"
          header="MALU WILZ"
          subheader="Pure Schönheit"
        />
        <cn-portfolio
          link="/Products"
          image="s030.jpg"
          header="Das Beste für Sie"
          subheader="Meine Produkte"
        />
      </div>
    </cn-page-content>
    <footer></footer>
  </cn-page>
</template>

<script lang="ts">
import CnPage from "@/components/CnPage.vue";
import CnPageHeader from "@/components/CnPageHeader.vue";
import CnPageContent from "@/components/CnPageContent.vue";
import { defineComponent } from "vue";
import CnPortfolio from "@/components/CnPortfolio.vue";

export default defineComponent({
  components: {
    CnPage,
    CnPageHeader,
    CnPageContent,
    CnPortfolio,
  },
});
</script>
