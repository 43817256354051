<template>
  <div class="review">
    <img title="quote-image" class="quote" :src="quote" />
    <cn-star :rating="rating" />
    <p class="review-text">
      <slot />
    </p>
    <p>{{ name }}</p>
  </div>
</template>

<script lang="ts">
import CnStar from "@/components/CnStar.vue";
import { useIcon } from "@/use/useImage";

import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnStar,
  },
  props: {
    name: String,
    rating: Number,
  },
  setup() {
    const quote = useIcon("quote.svg");
    return {
      quote,
    };
  },
});
</script>

<style lang="scss" scoped>
.review {
  position: relative;
}
.review-text {
  font-style: italic;
}
.quote {
  opacity: 0.1;
  position: absolute;

  top: 0;
  left: 15px;
}
</style>
