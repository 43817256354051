<template>
  <cn-main-wrapper>
    <cn-burger-menu></cn-burger-menu>
    <cn-main-menu>
      <cn-main-links></cn-main-links>
    </cn-main-menu>
    <cn-main-content>
      <router-view v-slot="{ Component }">
        <transition name="scale" :duration="800">
          <component :is="Component" />
        </transition>
      </router-view>
    </cn-main-content>
  </cn-main-wrapper>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import CnBurgerMenu from "@/components/CnBurgerMenu.vue";
import CnMainWrapper from "./components/CnMainWrapper.vue";
import CnMainContent from "./components/CnMainContent.vue";
import CnMainLinks from "./components/CnMainLinks.vue";
import CnMainMenu from "./components/CnMainMenu.vue";

export default defineComponent({
  components: {
    CnBurgerMenu,
    CnMainWrapper,
    CnMainContent,
    CnMainMenu,
    CnMainLinks,
  },
});
</script>

<style lang="scss">
@use "@/assets/styles/global";
@use "@/assets/styles/styles";

#app {
  height: 100%;
}

.scale-leave-to {
  transform: scale(var(--page-scale));
}

.scale-enter-from {
  transform: scale(var(--page-scale));
}

.scale-enter-active {
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  transform: translateX(100%) scale(var(--page-scale));
  transition: transform var(--transition-time);
  transition-delay: var(--transition-time) !important;
}

.scale-enter-to {
  z-index: 2;
  transform: translateX(0) scale(var(--page-scale));
}

.scale-leave-active {
  transition: transform var(--transition-time);
}
</style>
