import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_offer = _resolveComponent("cn-offer")!

  return (_openBlock(), _createBlock(_component_cn_offer, {
    title: _ctx.title,
    image: _ctx.image,
    subTitle: _ctx.subTitle,
    price: _ctx.price,
    duration: _ctx.duration,
    addition: _ctx.addition
  }, {
    default: _withCtx(() => [
      (_ctx.mainTitle)
        ? (_openBlock(), _createElementBlock("p", _hoisted_1, _toDisplayString(_ctx.mainTitle), 1))
        : _createCommentVNode("", true),
      _createElementVNode("p", null, _toDisplayString(_ctx.description), 1)
    ]),
    _: 1
  }, 8, ["title", "image", "subTitle", "price", "duration", "addition"]))
}