<template>
  <cn-offer
    title="Lust auf Beauty und Wellness?"
    image="s004.jpg"
    subTitle="Klassische Behandlung mit Massage"
    price="CHF 153.00"
    duration="90 Min"
  >
    <p>Abschalten, entspannen und geniessen</p>
    <p>Verwöhnen Sie Ihre Haut und Ihre Gesichtsmuskulatur.</p>
    <p>
      Reinigung, Hautdiagnose, Tiefenreinigung, Wirkstoffampulle, Gesichts-und
      Dekolleté Massage, entspannende Maske, Abschlusspflege
    </p>
  </cn-offer>
</template>

<script lang="ts">
import CnOffer from "@/components/CnOffer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnOffer,
  },
});
</script>
