import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_default_offer = _resolveComponent("cn-default-offer")!

  return (_openBlock(), _createBlock(_component_cn_default_offer, {
    image: "s017.jpg",
    title: "kurz und gründlich",
    subTitle: "Express Behandlung",
    mainTitle: "Kurzurlaub für Sie und Ihre Haut",
    description: " Reinigung, Hautdiagnose, individuelle Tiefenreinigung , gründliche Ausreinigung und wohltuende Maske, Abschlusspflege",
    duration: " ca. 60 Min",
    price: "CHF 98.00 / CHF 113.00*",
    addition: "*inkl. Wirkstoff-Ampulle"
  }))
}