<template>
  <cn-page>
    <cn-page-header title="Impressum" subtitle="cosmetic-nina"></cn-page-header>
    <cn-page-content>
      <cn-grid-container>
        <cn-grid-row space>
          <cn-grid-columns amount="six" left>
            <h6>Kontaktadresse</h6>
            <address>
              <strong>cosmetic nina</strong><br />
              Nina Goetschi<br />
              Rütistrasse 26<br />
              CH-8357 Guntershausen b. Aadorf<br />
              nina-g@sunrise.ch
            </address>
          </cn-grid-columns>
          <cn-grid-columns amount="six" left>
            <h6>Urheberrechte</h6>
            <p>
              Die Urheber- und alle anderen Rechte an Inhalten, Bildern, Fotos
              oder anderen Dateien auf der Website gehören ausschliesslich
              cosmetic nina oder den speziell genannten Rechtsinhabern. Für die
              Reproduktion jeglicher Elemente ist die schriftliche Zustimmung
              der Urheberrechtsträger im Voraus einzuholen.
            </p>
          </cn-grid-columns>
        </cn-grid-row>
        <cn-grid-row space>
          <cn-grid-columns amount="six" left>
            <h6>Haftungsausschluss</h6>
            <p>
              cosmetic nina übernimmt keinerlei Gewähr hinsichtlich der
              inhaltlichen Richtigkeit, Genauigkeit, Aktualität, Zuverlässigkeit
              und Vollständigkeit der Informationen.
            </p>
            <p>
              Haftungsansprüche gegen den Autor wegen Schäden materieller oder
              immaterieller Art, welche aus dem Zugriff oder der Nutzung bzw.
              Nichtnutzung der veröffentlichten Informationen, durch Missbrauch
              der Verbindung oder durch technische Störungen entstanden sind,
              werden ausgeschlossen.
            </p>
            <p>
              Alle Angebote sind unverbindlich. Der Autor behält es sich
              ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
              gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder
              die Veröffentlichung zeitweise oder endgültig einzustellen.
            </p>
          </cn-grid-columns>
          <cn-grid-columns amount="six" left>
            <h6>Datenschutz</h6>
            <p>
              Gestützt auf Artikel 13 der schweizerischen Bundesverfassung und
              die datenschutzrechtlichen Bestimmungen des Bundes
              (Datenschutzgesetz, DSG) hat jede Person Anspruch auf Schutz ihrer
              Privatsphäre sowie auf Schutz vor Missbrauch ihrer persönlichen
              Daten. Wir halten diese Bestimmungen ein. Persönliche Daten werden
              streng vertraulich behandelt und weder an Dritte verkauft noch
              weiter gegeben.
            </p>
            <p>
              In enger Zusammenarbeit mit unseren Hosting-Providern bemühen wir
              uns, die Datenbanken so gut wie möglich vor fremden Zugriffen,
              Verlusten, Missbrauch oder vor Fälschung zu schützen.
            </p>
            <p>
              Beim Zugriff auf unsere Webseiten werden folgende Daten in
              Logfiles gespeichert: IP-Adresse, Datum, Uhrzeit, Browser-Anfrage
              und allg. übertragene Informationen zum Betriebssystem resp.
              Browser. Diese Nutzungsdaten bilden die Basis für statistische,
              anonyme Auswertungen, so dass Trends erkennbar sind, anhand derer
              wir unsere Angebote entsprechend verbessern können.
            </p>
          </cn-grid-columns>
        </cn-grid-row>
      </cn-grid-container>
    </cn-page-content>
  </cn-page>
</template>

<script lang="ts">
import CnPage from "@/components/CnPage.vue";
import CnPageHeader from "@/components/CnPageHeader.vue";
import CnPageContent from "@/components/CnPageContent.vue";
import CnGridContainer from "@/components/CnGridContainer.vue";
import { defineComponent } from "vue";
import CnGridColumns from "@/components/CnGridColumns.vue";
import CnGridRow from "@/components/CnGridRow.vue";

export default defineComponent({
  components: {
    CnPage,
    CnGridRow,
    CnPageHeader,
    CnGridContainer,
    CnGridColumns,
    CnPageContent,
  },
});
</script>
