<template>
  <cn-default-page
    image="n1.jpg"
    title="Nina Goetschi."
    subtitle="Schön sie kennenzulernen"
    imagePosition="50% 15%"
  >
    <p>
      Nach 15-jähriger Tätigkeit im Bereich Schönheitspflege für Zuhause hatte
      ich im Jahr 2007 den Wunsch, mein eigenes Kosmetik-Studio zu eröffnen.
    </p>
    <p>
      In der renommierten Fachschule für Kosmetic (C. Heydecker) in Zürich,
      hatte ich die Möglichkeit, meine Ausbildung zur Kosmetikerin auf dem
      zweiten Bildungsweg zu absolvieren und mit Diplom abzuschliessen.
    </p>
    <p>
      Mit der Leidenschaft zum Beruf und dem nötigen Know-How ist es mein Ziel,
      Sie kompetent in den verschiedenen Bereichen der Kosmetik zu beraten und
      professionell zu behandeln.
    </p>
    <p>
      Regelmässige persönliche Weiterbildungskurse sind für mich ein absolutes
      Muss.
    </p>
  </cn-default-page>
</template>

<script lang="ts">
import CnDefaultPage from "@/components/CnDefaultPage.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnDefaultPage,
  },
});
</script>
