<template>
  <div class="carousel-container">
    <div
      class="carousel"
      ref="carusel"
      @mouseover="hover = true"
      @mouseleave="hover = false"
    >
      <div id="slide-1">
        <cn-quote :rating="5" name="Manuela aus Guntershausen">
          "Sehr Kompetent, sehr erfahren und jedes Mal sehr erholsam (ganz meine
          Zeit) Kann ich nur empfehlen."
        </cn-quote>
      </div>
      <div id="slide-2">
        <cn-quote :rating="5" name="Esther aus Elgg">
          "Ich freue mich immer wieder, wenn ich weiss, dass ein Nina Termin
          ansteht. 2 Stunden abtauchen und geniessen! Ich schätze es sehr, dass
          du immer der Jahreszeit entsprechende Behandlungen vorbereitet hast
          und mit den Produkten die du mir für die Heimpflege empfohlen hast,
          bin ich sehr zufrieden."
        </cn-quote>
      </div>
      <div id="slide-3">
        <cn-quote :rating="5" name="Christine aus Matzingen">
          "Bei Cosmetic nina fühle ich mich rundum einfach wohl. Ich schätze die
          unaufdringliche kompetente Art in der Behandlung, wie auch in der
          Produkteempfehlung. Vom Willkommensgruss bis zum Getränk für den
          Nachhauseweg, alles mit Liebe zum Detail. Und meine Augenbrauen,
          genial!"
        </cn-quote>
      </div>
      <div id="slide-4">
        <cn-quote :rating="5" name="Nicole aus Bütschwil">
          "Ich fahre schon seit Jahren von Bütschwil auf Guntershausen. 2
          Stunden die ich einfach voll geniesse. Jedes mal bin ich aufs neue
          begeistert was Sie mir anbietet. Ich kann cosmetic nina nur empfehlen,
          respektive ist es ein "Muss" einen Termin bei Nina zu vereinbaren."
        </cn-quote>
      </div>
    </div>
  </div>
  <div class="dots">
    <button
      v-for="n in elementsCount"
      :key="n"
      type="button"
      @click="scrollTo(n)"
      :class="{ active: activeNumber == n }"
    >
      n
    </button>
  </div>
</template>

<script lang="ts">
import CnQuote from "@/components/CnQuote.vue";
import { defineComponent, onMounted, ref } from "vue";

export default defineComponent({
  components: {
    CnQuote,
  },
  setup() {
    const carusel = ref<HTMLElement | null>(null);
    const hover = ref(false);
    const activeNumber = ref(1);
    const elementsCount = ref(0);

    const mounted = onMounted(() => {
      if (carusel.value) {
        elementsCount.value = carusel.value.childElementCount;

        setInterval(() => {
          if (!hover.value) {
            let newCurrent = activeNumber.value + 1;
            if (newCurrent > elementsCount.value) {
              newCurrent = 1;
            }
            scrollTo(newCurrent);
          }
        }, 9000);
      }
    });

    const handleScroll = (element: HTMLElement) => {
      console.log("scrolling");
      console.log(element);
    };

    const scrollTo = (id: number) => {
      if (carusel.value) {
        const element = carusel.value.querySelector("#slide-" + id);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
          activeNumber.value = id;
        }
      }
    };

    return {
      hover,
      elementsCount,
      activeNumber,
      carusel,
      mounted,
      scrollTo,
      handleScroll,
    };
  },
});
</script>

<style scoped lang="scss">
.carousel-container {
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth;
  margin: 0 auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.carousel-container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.carousel {
  display: flex;
  > div {
    flex: 0 0 100%;
    scroll-snap-align: start;
  }
  &:hover {
    animation-play-state: paused;
  }
}

.dots > button {
  display: inline-block;
  width: 10px;
  height: 10px;
  background-color: var(--color-font);
  border: 0.25rem solid transparent;
  border-radius: 50%;
  font-size: 0;
  padding: 0;
  margin: 10px;

  &.active {
    background-color: var(--color-orange);
  }
}
</style>
