<template>
  <div class="row" :class="{ 'space-after': space }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    space: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style lang="scss" scoped>
.row::after {
  content: "";
  display: table;
  clear: both;
}

.space-after {
  margin-bottom: 3rem;
}
</style>
