<template>
  <div class="columns" :class="[amount, { 'text-left': left }]">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";

export type Amount =
  | "one"
  | "two"
  | "three"
  | "four"
  | "five"
  | "six"
  | "seven"
  | "eight"
  | "nine"
  | "ten"
  | "eleven"
  | "twelve";

export default defineComponent({
  props: {
    amount: {
      type: String as PropType<Amount>,
      required: true,
    },
    left: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";

.columns {
  width: 100%;
  float: left;
  box-sizing: border-box;
}

.text-left {
  @include MG(Phablet) {
    text-align: left;
  }
}

@include MG(Phablet) {
  .columns {
    margin-left: 4%;
  }
  .columns:first-child {
    margin-left: 0;
  }
  .one.columns {
    width: 4.66666666667%;
  }
  .two.columns {
    width: 13.3333333333%;
  }
  .three.columns {
    width: 22%;
  }
  .four.columns {
    width: 30.6666666667%;
  }
  .five.columns {
    width: 39.3333333333%;
  }
  .six.columns {
    width: 48%;
  }
  .seven.columns {
    width: 56.6666666667%;
  }
  .eight.columns {
    width: 65.3333333333%;
  }
  .nine.columns {
    width: 74%;
  }
  .ten.columns {
    width: 82.6666666667%;
  }
  .eleven.columns {
    width: 91.3333333333%;
  }
  .twelve.columns {
    width: 100%;
    margin-left: 0;
  }
}
</style>
