<template>
  <div class="container">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/layout";

.container {
  position: relative;
  width: 100%; //max-width: 960px;
  margin: 0 auto;
  padding: 0 20px;
  box-sizing: border-box;

  @include MG(Mobile) {
    width: 85%;
    padding: 0;
  }

  @include MG(Phablet) {
    width: 80%;
  }
}
.container::after {
  content: "";
  display: table;
  clear: both;
}
</style>
