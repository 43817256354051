<template>
  <cn-page>
    <cn-page-header
      image="s011.jpg"
      title="Mehr für Ihr Wohlbefinden"
      subtitle="Zusatzangebote"
    ></cn-page-header>
    <cn-page-content>
      <cn-grid-container>
        <cn-grid-row>
          <cn-grid-columns amount="twelve">
            <h5>Wimpern / Brauen</h5>
            <p>
              Die Form und die Breite der Brauen, beeinflussen den Ausdruck
              Ihres Gesichtes entscheidend. Mit einer Wimpernfärbung ergänzen
              Sie die perfekte Ausstrahlung und Erscheinung.
            </p>
          </cn-grid-columns>
        </cn-grid-row>
        <cn-grid-row>
          <cn-grid-columns amount="six">
            <h6><span>Ohne Behandlung</span></h6>
            <cn-price-table-row
              left="Brauen zupfen pro min."
              right="CHF&nbsp;&nbsp;&nbsp;&nbsp;1.80"
            />
            <cn-price-table-row left="Brauen färben" right="CHF 25.00" />
            <cn-price-table-row left="Wimpern färben" right="CHF 37.00" />
          </cn-grid-columns>
          <cn-grid-columns amount="six">
            <h6><span>in der Behandlung</span></h6>
            <cn-price-table-row left="Brauen zupfen" right="gratis" />
            <cn-price-table-row left="Brauen färben" right="CHF 17.00" />
            <cn-price-table-row left="Wimpern färben" right="CHF 22.00" />
          </cn-grid-columns>
        </cn-grid-row>
        <hr />
        <cn-grid-row>
          <h5>Haarentfernung</h5>
          <p>Mit Warmwachs.</p>
        </cn-grid-row>
        <cn-grid-row>
          <cn-grid-columns amount="six">
            <h6><span>Ohne Behandlung</span></h6>
            <cn-price-table-row left="Oberlippe" right="CHF 15.00" />
            <cn-price-table-row left="Oberlippe und Kinn" right="CHF 28.00" />
            <cn-price-table-row left="Halbe Beine" right="CHF 42.00" />
            <cn-price-table-row left="Ganze Beine" right="CHF 70.00" />
            <cn-price-table-row left="Bikini" right="CHF 27.00" />
            <cn-price-table-row left="Achsel" right="CHF 23.00" />
          </cn-grid-columns>
          <cn-grid-columns amount="six">
            <h6><span>in der Behandlung</span></h6>
            <cn-price-table-row left="Oberlippe" right="CHF 10.00" />
            <cn-price-table-row left="Oberlippe und Kinn" right="CHF 15.00" />
          </cn-grid-columns>
        </cn-grid-row>
      </cn-grid-container>
    </cn-page-content>
    <footer></footer>
  </cn-page>
</template>

<script lang="ts">
import CnPage from "@/components/CnPage.vue";
import CnPageHeader from "@/components/CnPageHeader.vue";
import CnPageContent from "@/components/CnPageContent.vue";
import CnGridContainer from "@/components/CnGridContainer.vue";
import CnGridRow from "@/components/CnGridRow.vue";
import CnPriceTableRow from "@/components/CnPriceTableRow.vue";
import { defineComponent } from "vue";
import CnGridColumns from "@/components/CnGridColumns.vue";

export default defineComponent({
  components: {
    CnPage,
    CnPageHeader,
    CnGridContainer,
    CnGridColumns,
    CnPageContent,
    CnGridRow,
    CnPriceTableRow,
  },
});
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.shadow {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
