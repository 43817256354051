import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-310c196c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "carousel-container" }
const _hoisted_2 = { id: "slide-1" }
const _hoisted_3 = { id: "slide-2" }
const _hoisted_4 = { id: "slide-3" }
const _hoisted_5 = { id: "slide-4" }
const _hoisted_6 = { class: "dots" }
const _hoisted_7 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_quote = _resolveComponent("cn-quote")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", {
        class: "carousel",
        ref: "carusel",
        onMouseover: _cache[0] || (_cache[0] = ($event: any) => (_ctx.hover = true)),
        onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (_ctx.hover = false))
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_cn_quote, {
            rating: 5,
            name: "Manuela aus Guntershausen"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" \"Sehr Kompetent, sehr erfahren und jedes Mal sehr erholsam (ganz meine Zeit) Kann ich nur empfehlen.\" ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_cn_quote, {
            rating: 5,
            name: "Esther aus Elgg"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" \"Ich freue mich immer wieder, wenn ich weiss, dass ein Nina Termin ansteht. 2 Stunden abtauchen und geniessen! Ich schätze es sehr, dass du immer der Jahreszeit entsprechende Behandlungen vorbereitet hast und mit den Produkten die du mir für die Heimpflege empfohlen hast, bin ich sehr zufrieden.\" ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_cn_quote, {
            rating: 5,
            name: "Christine aus Matzingen"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" \"Bei Cosmetic nina fühle ich mich rundum einfach wohl. Ich schätze die unaufdringliche kompetente Art in der Behandlung, wie auch in der Produkteempfehlung. Vom Willkommensgruss bis zum Getränk für den Nachhauseweg, alles mit Liebe zum Detail. Und meine Augenbrauen, genial!\" ")
            ]),
            _: 1
          })
        ]),
        _createElementVNode("div", _hoisted_5, [
          _createVNode(_component_cn_quote, {
            rating: 5,
            name: "Nicole aus Bütschwil"
          }, {
            default: _withCtx(() => [
              _createTextVNode(" \"Ich fahre schon seit Jahren von Bütschwil auf Guntershausen. 2 Stunden die ich einfach voll geniesse. Jedes mal bin ich aufs neue begeistert was Sie mir anbietet. Ich kann cosmetic nina nur empfehlen, respektive ist es ein \"Muss\" einen Termin bei Nina zu vereinbaren.\" ")
            ]),
            _: 1
          })
        ])
      ], 544)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.elementsCount, (n) => {
        return (_openBlock(), _createElementBlock("button", {
          key: n,
          type: "button",
          onClick: ($event: any) => (_ctx.scrollTo(n)),
          class: _normalizeClass({ active: _ctx.activeNumber == n })
        }, " n ", 10, _hoisted_7))
      }), 128))
    ])
  ], 64))
}