<template>
  <section class="page">
    <slot />
  </section>
</template>

<style lang="scss" scoped>
.page {
  background: var(--color-white);
  height: 100%;
  overflow-y: auto;
  transition: transform var(--transition-time);
}
</style>
