<template>
  <cn-default-page
    image="s001.jpg"
    title="Herzlich Willkommen bei cosmetic nina"
    subtitle="Ihre Wohlfühloase auf dem Lande"
  >
    <h6>
      <span
        >Jung genug um innovativ zu sein, Alt genug um erfahren zu sein.</span
      >
    </h6>
    <p>
      Sie, Ihre Haut und Ihr Wohlbefinden stehen im Mittelpunkt. Jede Behandlung
      ist so einzigartig wie Sie und wird Ihrem und dem Bedürfnis Ihrer Haut
      angepasst.
    </p>
    <h6>
      <span>Ich freue mich auf Sie.<br />Nina Goetschi</span>
    </h6>
    <hr />
    <h6 class="feedbacks">Kundenfeedbacks:</h6>
    <cn-slider />
  </cn-default-page>
</template>

<script lang="ts">
import CnDefaultPage from "@/components/CnDefaultPage.vue";
import CnSlider from "@/components/CnSlider.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnDefaultPage,
    CnSlider,
  },
});
</script>

<style lang="scss" scoped>
.feedbacks {
  padding-top: 5rem;
}
</style>
