<template>
  <main>
    <slot />
  </main>
</template>

<style lang="scss" scoped>
@import "@/assets/styles/layout";

main {
  @include MG(Desktop) {
    position: relative;
    width: auto;
    height: 100%;
  }

  background-color: var(--color-white);
  overflow-x: hidden;
  height: calc(100% - var(--miniheader-height));
  width: 100%;
  position: absolute;
}
</style>
