<template>
  <cn-grid-row class="space-after">
    <cn-grid-columns amount="eight" left>
      {{ left }}
    </cn-grid-columns>
    <cn-grid-columns amount="four">
      {{ right }}
    </cn-grid-columns>
  </cn-grid-row>
</template>

<script>
import { defineComponent } from "vue";
import CnGridRow from "@/components/CnGridRow.vue";
import CnGridColumns from "@/components/CnGridColumns.vue";

export default defineComponent({
  props: {
    left: {
      Type: String,
    },
    right: {
      Type: String,
    },
  },
  components: {
    CnGridRow,
    CnGridColumns,
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";

.space-after {
  margin-bottom: 15px;
  @include MG(Phablet) {
    margin-bottom: 0;
  }
}
</style>
