<template>
  <cn-default-offer
    image="s017.jpg"
    title="kurz und gründlich"
    subTitle="Express Behandlung"
    mainTitle="Kurzurlaub für Sie und Ihre Haut"
    description=" Reinigung, Hautdiagnose, individuelle Tiefenreinigung , gründliche Ausreinigung und wohltuende Maske, Abschlusspflege"
    duration=" ca. 60 Min"
    price="CHF 98.00 / CHF 113.00*"
    addition="*inkl. Wirkstoff-Ampulle"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CnDefaultOffer from "@/components/CnDefaultOffer.vue";

export default defineComponent({
  components: {
    CnDefaultOffer,
  },
});
</script>
