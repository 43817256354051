<template>
  <div class="page-wrapper">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
.page-wrapper {
  border: var(--border-size) solid var(--color-orange);
  height: 100%;
  position: relative;
}
</style>
