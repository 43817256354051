import { menuStore } from "@/use/useMenu";
import AboutView from "@/views/AboutView.vue";
import ContactView from "@/views/ContactView.vue";
import FriendsDayView from "@/views/FriendsDayView.vue";
import HomeView from "@/views/HomeView.vue";
import ImpressumView from "@/views/ImpressumView.vue";
import AdditionalsView from "@/views/Offers/AdditionalsView.vue";
import AntiAgingView from "@/views/Offers/AntiAgingView.vue";
import ClassicMassageView from "@/views/Offers/ClassicMassageView.vue";
import CosmeticView from "@/views/Offers/CosmeticView.vue";
import ExpressTreatmentView from "@/views/Offers/ExpressTreatmentView.vue";
import ExpressUltrasonicView from "@/views/Offers/ExpressUltrasonicView.vue";
import LymphdrainageView from "@/views/Offers/LymphdrainageView.vue";
import MikrodermabrasionView from "@/views/Offers/MikrodermabrasionView.vue";
import TeenagerView from "@/views/Offers/TeenagerView.vue";
import OffersView from "@/views/OffersView.vue";
import ProductsView from "@/views/ProductsView.vue";
import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";

const siteName = "cosmetic-nina";

//TODO: Lazy Loading
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: HomeView,
  },
  {
    path: "/offers",
    name: "Angebote",
    alias: "/Angebote",
    component: OffersView,
  },
  {
    path: "/products",
    name: "Produkte",
    alias: "/Produkte",
    component: ProductsView,
  },
  {
    path: "/friends-day",
    name: "Freundinnen Tag",
    alias: "/Freundinnen-Tag",
    component: FriendsDayView,
  },
  {
    path: "/about",
    name: "About",
    alias: "/Ueber-mich",
    component: AboutView,
  },
  {
    path: "/contact",
    name: "Kontakt",
    alias: "/Kontakt",
    component: ContactView,
  },
  {
    path: "/impressum",
    name: "Impressum",
    component: ImpressumView,
  },
  {
    path: "/offers/express-treatment",
    name: "Express Behandlung",
    alias: "/Angebote/Express-Behandlung",
    component: ExpressTreatmentView,
  },
  {
    path: "/offers/teenager",
    name: "Teenager",
    alias: "/Angebote/Teenager",
    component: TeenagerView,
  },
  {
    path: "/offers/classic-massage",
    name: "Klassische Massage",
    alias: "/Angebote/Klassische-Massage",
    component: ClassicMassageView,
  },
  {
    path: "/offers/express-ultrasonic",
    name: "Express Ultraschal",
    alias: "/Angebote/Express-Ultraschal",
    component: ExpressUltrasonicView,
  },
  {
    path: "/offers/mikrodermabrasion",
    name: "Mikrodermabrasion",
    alias: "/Angebote/mikrodermabrasion",
    component: MikrodermabrasionView,
  },
  {
    path: "/offers/anti-aging",
    name: "Anti Aging",
    alias: "/Angebote/anti-aging",
    component: AntiAgingView,
  },
  {
    path: "/offers/Lymphdrainage",
    name: "Lymphdrainage",
    alias: "/Angebote/Lymphdrainage",
    component: LymphdrainageView,
  },
  {
    path: "/offers/Additionals",
    name: "Zusatzangebote",
    alias: "/Angebote/Zusatzangebote",
    component: AdditionalsView,
  },
  {
    path: "/offers/Cosmetic",
    name: "Dekorative Kosmetik",
    alias: "/Angebote/Dekorative-Kosmetik",
    component: CosmeticView,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
];

const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "selected",
  routes,
});

router.beforeEach(() => {
  if (menuStore.isOpen) {
    menuStore.toggle();
  }
});

router.afterEach((to) => {
  if (to.name) {
    const name = to.name.toString() + " - " + siteName;
    document.title = name;
  } else {
    document.title = siteName;
  }
});

export default router;
