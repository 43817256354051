<template>
  <header class="miniheader">
    <button
      class="minimenu hamburger hamburger--collapse"
      :class="{ 'is-active': menuStore.isOpen }"
      aria-label="Open Menu"
      type="button"
      @click="menuStore.toggle"
    >
      <span class="hamburger-box">
        <span class="hamburger-inner"></span>
      </span>
    </button>
    <figure>
      <router-link to="/"
        ><img src="@/assets/images/Logo@45.jpg" alt="Logo"
      /></router-link>
    </figure>
  </header>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { menuStore } from "@/use/useMenu";

export default defineComponent({
  data() {
    return { menuStore };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";
@import "@/assets/styles/hamburgers/hamburgers";

.miniheader {
  @include MG(Desktop) {
    display: none;
  }

  height: var(--miniheader-height);
  background-color: var(--color-orange);

  figure {
    width: 45px;
    margin: auto;
    padding: 2.5px;
  }

  img {
    width: 100%; //border: 1px solid $white;
  }
}

.minimenu {
  display: block;
  cursor: pointer;
  border: none;
  background: none;
  position: absolute;
  left: 1rem;
  top: 0.9rem;
  color: #ff8;
}
</style>
