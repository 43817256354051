<template>
  <cn-offer
    title="Kurz, gründlich und effektvoll"
    image="s006.jpg"
    subTitle="Express Behandlung mit Ultraschall"
    price="CHF 139.00*"
    duration="ca. 60 Min"
    addition="*inkl. Wirkstoff-Ampulle"
  >
    <p>Feiner ist schöner</p>
    <p>
      Reinigung, Hautdiagnose, Tiefenreinigung mit Ultraschall SkinQ2,
      gründliche Ausreinigung, entspannende Gesichtsmaske, Abschlusspflege
    </p>
  </cn-offer>
</template>

<script lang="ts">
import CnOffer from "@/components/CnOffer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnOffer,
  },
});
</script>
