<template>
  <ul>
    <li>
      <router-link to="/">HOME</router-link>
    </li>
    <li>
      <router-link to="/offers" :class="{ selected: isSubOffer }"
        >ANGEBOTE</router-link
      >
    </li>
    <li><router-link to="/products">PRODUKTE</router-link></li>
    <li>
      <router-link to="/friends-day">FREUNDINNEN TAG</router-link>
    </li>
    <li><router-link to="/about">ÜBER MICH</router-link></li>
    <li><router-link to="/contact">KONTAKT</router-link></li>
  </ul>
</template>

<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  setup() {
    const route = useRoute();
    const isSubOffer = ref(false);

    watch(
      () => route.path,
      (newRoute) => {
        isSubOffer.value =
          newRoute.toUpperCase().startsWith("/OFFERS") &&
          (newRoute.match(/\//g) || []).length >= 2;
      },
    );

    return {
      isSubOffer,
    };
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/styles/layout";
li {
  @include MG(Desktop) {
    text-align: right;
  }
  list-style-type: none;
  text-align: center;
  font-size: 16px;
  font-weight: 800;

  &:hover {
    a {
      color: var(--color-white);
    }
  }

  a {
    color: var(--color-black);
    margin: 0px;
    padding: 1.6rem;
    display: block;
    width: 100%;
    height: 100%;

    &.selected {
      transition: all 0.5s;
      color: var(--color-white);
      background-color: var(--color-gray);
    }
  }
}
</style>
