<template>
  <header>
    <div class="bg-color"></div>
    <transition name="fade">
      <div
        v-if="show && image"
        class="bg-image"
        :style="{
          backgroundImage: `url(${imageUrl})`,
          backgroundPosition: `${imagePosition}`,
        }"
      ></div>
    </transition>
    <div class="bg-opacity"></div>
    <cn-grid-container class="title">
      <h1>{{ title }}</h1>
      <h6 v-if="subtitle">{{ subtitle }}</h6>
    </cn-grid-container>
  </header>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import { useImage } from "@/use/useImage";
import CnGridContainer from "@/components/CnGridContainer.vue";

export default defineComponent({
  props: {
    image: {
      type: String,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
    },
    imagePosition: {
      type: String,
      default: "50% 0",
    },
  },
  setup(props) {
    const show = ref(false);
    const imageUrl = useImage(props.image ?? "");

    setTimeout(() => {
      show.value = true;
    }, 300);

    return {
      imageUrl,
      show,
    };
  },
  components: {
    CnGridContainer,
  },
});
</script>

<style lang="scss" scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
header {
  color: var(--color-white);
  text-align: center;
  position: relative;
  height: 50%;
  max-height: 700px;
  min-height: 300px;

  h1 {
    font-weight: 900;
  }

  .bg-color {
    position: absolute;
    left: 0;
    right: 0;
    height: 100%;
    background-color: #000000;
  }

  .bg-opacity {
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    &:before {
      content: "";
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(29, 30, 32, 0.19);
    }
  }

  .bg-image {
    background-size: cover;
    background-position: 50% 0;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    right: 0;
    top: -2px;
    bottom: 0;
  }

  .title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
