import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5c0b7a69"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "stars" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rating, (n) => {
      return (_openBlock(), _createElementBlock("img", {
        title: "rating",
        key: n,
        src: _ctx.star
      }, null, 8, _hoisted_2))
    }), 128))
  ]))
}