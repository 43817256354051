<template>
  <cn-offer
    title="Der Weg zur perfekten Haut"
    image="s008.jpg"
    subTitle="Mikrodermabrasion"
    price="CHF 165.00"
    duration="ca. 90 Min"
  >
    <p>
      Erleben Sie ein präzises Peeling - Ergebnis durch Diamanten. Unter
      Verwendung von dicht mit lasergeschliffenen Diamanten besetzten Aufsätzen
      werden abgestorbene Hautzellen entfernt und das Wachstum gesunder neuer
      Zellen angeregt. Die Mikroexfoliation ist für alle Hauttypen geeignet,
      denn es wird <strong>ohne Säure</strong> gearbeitet. Dieses Angebot gibt
      es auch als Kurbehandlung.
    </p>

    <h6><span>Mikroexfoliation Effekte</span></h6>
    <div class="listing">
      <ul>
        <li>Aufbau und Erneuerung der Hautzellen</li>
        <li>Aufbau lichtgeschädigter Haut</li>
        <li>Mildert Pigmentflecken</li>
        <li>Verfeinert die Poren</li>
      </ul>
    </div>
    <p>
      Die Haut wird gestrafft und erhält ein frisches, pralles und gesundes
      Aussehen.
    </p>
  </cn-offer>
</template>

<script lang="ts">
import CnOffer from "@/components/CnOffer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnOffer,
  },
});
</script>
