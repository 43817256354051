<template>
  <cn-default-offer
    image="s019.jpg"
    title="Für junge Menschen bis zum 20. Lebensjahr"
    subTitle="Teenager Behandlung"
    description="Akne ist eine Sammelbezeichnung für Erkrankungen des Talgdrüsenapparates und der Haarfollikel.
                        Bei unreiner Haut entscheide ich individuell und nach genauer Hautanalyse die Behandlungsdauer
                        und -methode. Bei sehr starker Akne und entzündlichen Hautveränderungen ist die Zusammenarbeit
                        mit einem Dermatologen unerlässlich."
    duration=" ca. 60 Min"
    price=" CHF 78.00 bis CHF 120.00"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CnDefaultOffer from "@/components/CnDefaultOffer.vue";

export default defineComponent({
  components: {
    CnDefaultOffer,
  },
});
</script>
