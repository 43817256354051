import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("ul", null, [
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: "/" }, {
        default: _withCtx(() => [
          _createTextVNode("HOME")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, {
        to: "/offers",
        class: _normalizeClass({ selected: _ctx.isSubOffer })
      }, {
        default: _withCtx(() => [
          _createTextVNode("ANGEBOTE")
        ]),
        _: 1
      }, 8, ["class"])
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: "/products" }, {
        default: _withCtx(() => [
          _createTextVNode("PRODUKTE")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: "/friends-day" }, {
        default: _withCtx(() => [
          _createTextVNode("FREUNDINNEN TAG")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: "/about" }, {
        default: _withCtx(() => [
          _createTextVNode("ÜBER MICH")
        ]),
        _: 1
      })
    ]),
    _createElementVNode("li", null, [
      _createVNode(_component_router_link, { to: "/contact" }, {
        default: _withCtx(() => [
          _createTextVNode("KONTAKT")
        ]),
        _: 1
      })
    ])
  ]))
}