<template>
  <cn-default-offer
    image="s002.jpg"
    title="Genuss pur"
    subTitle="Manuelle Gesichts-Lymphdrainage"
    description="Mit sanften rhythmischen Bewegungen entlang der Lymphbahnen, gelingt es mit der manuellen
                        Lymphdrainage (MLD), Gesicht und Hals von Schad-und Giftstoffen zu befreien und neu erstrahlen
                        zu lassen. Die feinen mechanischen Reize der MLD wirken direkt auf das vegetative Nervensystem
                        wie z.B Kreislauf, Atmung, Verdauung, Stoffwechsel, Tonus der Muskulatur und Gefässe. Der
                        Lymphfluss wird angeregt und Stauungen unter der Haut gelöst. z.B Tränensäcke. Durch die MLD
                        werden das Bindegewebe und die Muskeln stimuliert, was gleichzeitig eine erhöhte Durchblutung
                        zur Folge hat. Ihr Gesicht entspannt und erstrahlt nach kurzer Zeit!"
    duration=" ca. 60 Min"
    price="CHF 105.00"
  />
</template>
<script lang="ts">
import { defineComponent } from "vue";
import CnDefaultOffer from "@/components/CnDefaultOffer.vue";

export default defineComponent({
  components: {
    CnDefaultOffer,
  },
});
</script>
