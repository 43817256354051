<template>
  <cn-offer
    title="MALU WILZ"
    image="s013.jpg"
    subTitle="Pure Schönheit"
    price="CHF 50.00 bis CHF 130.00"
    duration="je nach Aufwand"
  >
    <blockquote>
      <cite>
        „Ein Make-up das täglich getragen wird, sollte nicht das Gesicht der
        Trägerin verstecken, im Gegenteil, es soll zeigen wie einzigartig und
        schön dieses Gesicht ist!“
      </cite>
    </blockquote>
    <p>
      Die dekorative Linie von MALU WILZ unterstreicht die Schönheit jeder Frau.
      Gerne verrate ich Ihnen Tipps und Tricks für ein frisches Aussehen.
    </p>

    <h6><span>Tages- / Abend Make-up</span></h6>
  </cn-offer>
</template>

<script lang="ts">
import CnOffer from "@/components/CnOffer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnOffer,
  },
});
</script>
