<template>
  <cn-offer
    :title="title"
    :image="image"
    :subTitle="subTitle"
    :price="price"
    :duration="duration"
    :addition="addition"
  >
    <p v-if="mainTitle">{{ mainTitle }}</p>
    <p>
      {{ description }}
    </p>
  </cn-offer>
</template>

<script lang="ts">
import CnOffer from "@/components/CnOffer.vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    mainTitle: {
      type: String,
    },
    description: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
    },
    addition: {
      type: String,
    },
  },
  components: {
    CnOffer,
  },
});
</script>
