import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_default_offer = _resolveComponent("cn-default-offer")!

  return (_openBlock(), _createBlock(_component_cn_default_offer, {
    image: "s019.jpg",
    title: "Für junge Menschen bis zum 20. Lebensjahr",
    subTitle: "Teenager Behandlung",
    description: "Akne ist eine Sammelbezeichnung für Erkrankungen des Talgdrüsenapparates und der Haarfollikel.\n                        Bei unreiner Haut entscheide ich individuell und nach genauer Hautanalyse die Behandlungsdauer\n                        und -methode. Bei sehr starker Akne und entzündlichen Hautveränderungen ist die Zusammenarbeit\n                        mit einem Dermatologen unerlässlich.",
    duration: " ca. 60 Min",
    price: " CHF 78.00 bis CHF 120.00"
  }))
}