<template>
  <cn-default-page :image="image" :title="title" :subtitle="subTitle">
    <slot />
    <p><span class="glyphicon glyphicon-time"></span>{{ duration }}</p>
    <p>
      {{ price }}<br />
      <small v-if="addition">{{ addition }}</small>
    </p>
  </cn-default-page>
</template>

<script lang="ts">
import CnDefaultPage from "@/components/CnDefaultPage.vue";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    image: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subTitle: {
      type: String,
      required: true,
    },
    price: {
      type: String,
      required: true,
    },
    duration: {
      type: String,
    },
    addition: {
      type: String,
    },
  },
  components: {
    CnDefaultPage,
  },
});
</script>
