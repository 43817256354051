<template>
  <article :class="{ fullScreen: fullScreen }">
    <slot />
  </article>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  props: {
    fullScreen: {
      type: Boolean,
    },
  },
});
</script>

<style lang="scss" scoped>
article {
  width: auto;
  text-align: center;
  margin: auto;
  padding: 60px 0;

  &.fullScreen {
    padding: 0;
  }
}
</style>
