<template>
  <cn-page>
    <cn-page-header
      image="s028.jpg"
      title="Das Beste für Sie"
      subtitle="Meine Produkte"
    ></cn-page-header>
    <cn-page-content>
      <cn-grid-container>
        <cn-grid-row>
          <cn-grid-columns amount="six">
            <h4>DR. <span>Grandel</span></h4>
            <h5>Perfektion, die berührt!</h5>
            <p>
              Dr. Grandel ist ein unabhängiges Familienunternehmen aus Ausburg
              mit 70-jähriger Tradition und Kosmetik-Expertise. Höchster
              Anspruch und wissenschaftliche Methoden sorgen für eine Kosmetik,
              die auf optimierter Natur (Made in Germany) setzt. Strenge interne
              Standards auf höchstem wissenschaftlichen Niveau garantieren beste
              Qualität. Dermatologische Tests von unabhängigen Instituten und
              Kliniken bestätigen die Wirksamkeit und Verträglichkeit. Das gibt
              das sichere Gefühl, sich für DAS BESTE entschieden zu haben.
            </p>
            <a href="https://www.grandel.de" target="_blank">www.grandel.de</a>
          </cn-grid-columns>
          <cn-grid-columns amount="six">
            <img src="@/assets/images/p005.jpg" alt="Dr. Grandel" />
          </cn-grid-columns>
        </cn-grid-row>
        <hr />
        <cn-grid-row>
          <cn-grid-columns amount="six">
            <h4><span>Malu Wilz</span> Beauté</h4>
            <p>
              Die Profi-Linie bietet sowohl qualitativ hochwertige
              Pflegeprodukte, die die Haut von innen unterstützen und
              regenerieren, als auch exklusive und langanhaltende dekorative
              Kosmetik, die die Schönheit jeder Frau unterstreicht.
            </p>
            <a href="https://www.maluwilz.de" target="_blank"
              >www.maluwilz.de</a
            >
          </cn-grid-columns>
          <cn-grid-columns amount="six">
            <img
              src="@/assets/images/p004.jpg"
              class="shadow"
              alt="Malu Wilz"
            />
          </cn-grid-columns>
        </cn-grid-row>
      </cn-grid-container>
    </cn-page-content>
    <footer></footer>
  </cn-page>
</template>

<script lang="ts">
import CnPage from "@/components/CnPage.vue";
import CnPageHeader from "@/components/CnPageHeader.vue";
import CnPageContent from "@/components/CnPageContent.vue";
import CnGridContainer from "@/components/CnGridContainer.vue";
import CnGridRow from "@/components/CnGridRow.vue";
import { defineComponent } from "vue";
import CnGridColumns from "@/components/CnGridColumns.vue";

export default defineComponent({
  components: {
    CnPage,
    CnPageHeader,
    CnGridContainer,
    CnGridColumns,
    CnPageContent,
    CnGridRow,
  },
});
</script>

<style lang="scss" scoped>
img {
  width: 100%;
}

.shadow {
  box-shadow:
    0 4px 8px 0 rgba(0, 0, 0, 0.2),
    0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
</style>
