<template>
  <div class="stars">
    <img title="rating" v-for="n in rating" :key="n" :src="star" />
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { useIcon } from "@/use/useImage";

export default defineComponent({
  props: {
    rating: {
      type: Number,
    },
  },
  setup() {
    const star = useIcon("star.svg");
    return {
      star,
    };
  },
});
</script>

<style lang="scss" scoped>
.stars {
  padding: 1em 0;
  & > img {
    padding: 0 0.1em;
  }
}
</style>
