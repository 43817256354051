<template>
  <cn-default-page
    image="s033.jpg"
    title="Freundinnen Tag"
    subtitle="Eine Prinzessin ohne Krone, nennt man BESTE FREUNDIN"
  >
    <h6><span>Verwöhn- Zeit</span></h6>
    <p>
      Sie sind eine oder mehrere Freundinnen und Ihre beste Freundin hat
      Geburtstag, Hochzeit, ect Ein Beauty- Erlebnis das berührt. Lassen Sie
      mich wissen, wann ich mit Ihnen Kontakt aufnehmen darf um Einzelheiten zu
      besprechen.
    </p>
    <p>
      <router-link to="/contact">Zum Kontakt Formular</router-link>
    </p>
  </cn-default-page>
</template>

<script lang="ts">
import CnDefaultPage from "@/components/CnDefaultPage.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    CnDefaultPage,
  },
});
</script>
