import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cn_default_offer = _resolveComponent("cn-default-offer")!

  return (_openBlock(), _createBlock(_component_cn_default_offer, {
    image: "s002.jpg",
    title: "Genuss pur",
    subTitle: "Manuelle Gesichts-Lymphdrainage",
    description: "Mit sanften rhythmischen Bewegungen entlang der Lymphbahnen, gelingt es mit der manuellen\n                        Lymphdrainage (MLD), Gesicht und Hals von Schad-und Giftstoffen zu befreien und neu erstrahlen\n                        zu lassen. Die feinen mechanischen Reize der MLD wirken direkt auf das vegetative Nervensystem\n                        wie z.B Kreislauf, Atmung, Verdauung, Stoffwechsel, Tonus der Muskulatur und Gefässe. Der\n                        Lymphfluss wird angeregt und Stauungen unter der Haut gelöst. z.B Tränensäcke. Durch die MLD\n                        werden das Bindegewebe und die Muskeln stimuliert, was gleichzeitig eine erhöhte Durchblutung\n                        zur Folge hat. Ihr Gesicht entspannt und erstrahlt nach kurzer Zeit!",
    duration: " ca. 60 Min",
    price: "CHF 105.00"
  }))
}